// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-3-0-js": () => import("./../../../src/pages/About-3.0.js" /* webpackChunkName: "component---src-pages-about-3-0-js" */),
  "component---src-pages-about-vascepa-history-js": () => import("./../../../src/pages/about-vascepa/history.js" /* webpackChunkName: "component---src-pages-about-vascepa-history-js" */),
  "component---src-pages-about-vascepa-how-it-works-js": () => import("./../../../src/pages/about-vascepa/how-it-works.js" /* webpackChunkName: "component---src-pages-about-vascepa-how-it-works-js" */),
  "component---src-pages-about-vascepa-js": () => import("./../../../src/pages/about-vascepa.js" /* webpackChunkName: "component---src-pages-about-vascepa-js" */),
  "component---src-pages-about-vascepa-safety-and-side-effects-js": () => import("./../../../src/pages/about-vascepa/safety-and-side-effects.js" /* webpackChunkName: "component---src-pages-about-vascepa-safety-and-side-effects-js" */),
  "component---src-pages-benefits-2-0-js": () => import("./../../../src/pages/Benefits-2.0.js" /* webpackChunkName: "component---src-pages-benefits-2-0-js" */),
  "component---src-pages-benefits-clinical-trial-results-js": () => import("./../../../src/pages/benefits/clinical-trial-results.js" /* webpackChunkName: "component---src-pages-benefits-clinical-trial-results-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-cardiovascular-risk-diabetes-and-heart-disease-js": () => import("./../../../src/pages/cardiovascular-risk/diabetes-and-heart-disease.js" /* webpackChunkName: "component---src-pages-cardiovascular-risk-diabetes-and-heart-disease-js" */),
  "component---src-pages-cardiovascular-risk-healthy-heart-tips-js": () => import("./../../../src/pages/cardiovascular-risk/healthy-heart-tips.js" /* webpackChunkName: "component---src-pages-cardiovascular-risk-healthy-heart-tips-js" */),
  "component---src-pages-cardiovascular-risk-js": () => import("./../../../src/pages/cardiovascular-risk.js" /* webpackChunkName: "component---src-pages-cardiovascular-risk-js" */),
  "component---src-pages-cardiovascular-risk-protect-against-another-heart-attack-or-stroke-js": () => import("./../../../src/pages/cardiovascular-risk/protect-against-another-heart-attack-or-stroke.js" /* webpackChunkName: "component---src-pages-cardiovascular-risk-protect-against-another-heart-attack-or-stroke-js" */),
  "component---src-pages-cardiovascular-risk-quiz-js": () => import("./../../../src/pages/cardiovascular-risk/quiz.js" /* webpackChunkName: "component---src-pages-cardiovascular-risk-quiz-js" */),
  "component---src-pages-cardiovascular-risk-talking-with-your-doctor-js": () => import("./../../../src/pages/cardiovascular-risk/talking-with-your-doctor.js" /* webpackChunkName: "component---src-pages-cardiovascular-risk-talking-with-your-doctor-js" */),
  "component---src-pages-files-js": () => import("./../../../src/pages/files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-getting-started-dosing-js": () => import("./../../../src/pages/getting-started/dosing.js" /* webpackChunkName: "component---src-pages-getting-started-dosing-js" */),
  "component---src-pages-getting-started-helpful-resources-js": () => import("./../../../src/pages/getting-started/helpful-resources.js" /* webpackChunkName: "component---src-pages-getting-started-helpful-resources-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-getting-started-vascepa-savings-js": () => import("./../../../src/pages/getting-started/vascepa-savings.js" /* webpackChunkName: "component---src-pages-getting-started-vascepa-savings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-3-1-1-js": () => import("./../../../src/pages/page-3.1.1.js" /* webpackChunkName: "component---src-pages-page-3-1-1-js" */),
  "component---src-pages-page-3-1-2-js": () => import("./../../../src/pages/page-3.1.2.js" /* webpackChunkName: "component---src-pages-page-3-1-2-js" */),
  "component---src-pages-raisearedflag-js": () => import("./../../../src/pages/raisearedflag.js" /* webpackChunkName: "component---src-pages-raisearedflag-js" */)
}

